var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-management"},[(_vm.editMapMode)?_c('div',{staticClass:"map-management-header"},[_c('div',{staticClass:"map-management-header-left"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("COMMON.ADD_LOCATIONS_POINTS"))+" "),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()]),(!_vm.filteredSpot)?_c('base-input',{attrs:{"placeholder":`${_vm.$t('SPOTS.MAP_POINT_SIZE')}`}},[_c('spot-selector',{on:{"spotChanged":(spotId) => {
              _vm.selectedSpot = spotId;
            }}})],1):_vm._e(),_c('base-input',{attrs:{"placeholder":`${_vm.$t('SPOTS.MAP_POINT_SIZE')}`,"type":"number"},on:{"change":_vm.updateMapData},model:{value:(_vm.mapData.pointSize),callback:function ($$v) {_vm.$set(_vm.mapData, "pointSize", $$v)},expression:"mapData.pointSize"}}),_c('base-input',[_c('base-checkbox',{model:{value:(_vm.showHelper),callback:function ($$v) {_vm.showHelper=$$v},expression:"showHelper"}},[_vm._v(" "+_vm._s(_vm.$t("SPOTS.SHOW_HELPER"))+" ")])],1),_c('base-input',[_c('base-checkbox',{model:{value:(_vm.showCodes),callback:function ($$v) {_vm.showCodes=$$v},expression:"showCodes"}},[_vm._v(" "+_vm._s(_vm.$t("SPOTS.SHOW_CODES"))+" ")])],1)],1),_c('div',{staticClass:"map-management-header-right"},[_c('image-selector',{attrs:{"ressource_id":_vm.globalOrganization ? _vm.globalOrganization.id : 0,"ressource_name":"options","field":"map_picture","label":`${_vm.$t('COMMON.ADD_MAP_IMAGE')}`,"inline":""},on:{"imageChanged":_vm.mapImageChanged}})],1)]):_vm._e(),_c('div',{staticClass:"map-management-content"},[_c('div',{ref:"mapContainer",staticClass:"image-container",class:{
        dragging: _vm.zoomOptions.isDragging,
        draggingSpot: _vm.currentSpot.isDragging,
        loading: _vm.loading,
      },on:{"mousedown":function($event){$event.preventDefault();return _vm.onMouseDownImage.apply(null, arguments)},"mousemove":function($event){$event.preventDefault();return _vm.onMouseMoveImage.apply(null, arguments)},"mouseup":function($event){$event.preventDefault();return _vm.onMouseUpImage.apply(null, arguments)},"mouseleave":function($event){$event.preventDefault();return _vm.onMouseLeaveImage.apply(null, arguments)},"wheel":function($event){$event.preventDefault();return _vm.zoom.apply(null, arguments)},"contextmenu":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('img',{ref:"imageContainer",style:(_vm.imageStyle),attrs:{"id":"map-image","src":_vm.imageSrc}}),(_vm.showMapForm)?_c('div',{ref:"mapFormContainer",staticClass:"map-form",style:(_vm.getMapFormStyle())},[(!_vm.filteredSpot)?_c('div',{staticClass:"map-form-header"},[_c('p',[_vm._v(_vm._s(_vm.$t("COMMON.ADD_LOCATION")))]),_c('button',{staticClass:"close",on:{"click":_vm.closeMapForm}},[_c('i',{staticClass:"fal fa-times"})])]):_vm._e(),_c('div',{staticClass:"map-form-content"},[(!_vm.filteredSpot)?_c('el-select',{attrs:{"filterable":true,"placeholder":`${_vm.$t('COMMON.SEARCH_LOCATION')}`},on:{"change":(value) => {
                _vm.updateSpotMapPosition(value, _vm.currentSpot.x, _vm.currentSpot.y);
              }},model:{value:(_vm.currentSpot.spotId),callback:function ($$v) {_vm.$set(_vm.currentSpot, "spotId", $$v)},expression:"currentSpot.spotId"}},_vm._l((_vm.spots),function(spot){return _c('el-option',{key:spot.id,class:{
                'spot-used':
                  spot.map_position_x != 0 && spot.map_position_y != 0,
              },attrs:{"value":spot.id,"label":`#${spot.code} - ${spot.name}`}})}),1):_vm._e(),_c('div',{staticClass:"location"},[_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.SPOT")))]),(!_vm.filteredSpot)?_c('div',{staticClass:"location-selected"},[(_vm.spots[_vm.currentSpot.spotId]?.id)?_c('div',{staticClass:"located"},[_c('div',{staticClass:"located-image"},[_c('img',{staticStyle:{"max-height":"35px","display":"inline"},attrs:{"src":_vm.spots[_vm.currentSpot.spotId].category.icon
                        ? _vm.spots[_vm.currentSpot.spotId].category.icon
                        : '/img/default-category-icon.svg',"alt":"icon"}})]),_c('div',{staticClass:"located-text"},[_c('span',[_vm._v(_vm._s(_vm.spots[_vm.currentSpot.spotId].code))]),_c('span',[_vm._v(" "+_vm._s(_vm.spots[_vm.currentSpot.spotId].spotType.name)+" ")])]),_c('div',{staticClass:"located-delete"},[_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.deleteSpotPosition(_vm.currentSpot.spotId)}}},[_c('img',{attrs:{"src":"/img/e-trash.svg","alt":"icon"}})])])]):_c('p',[_vm._v(_vm._s(_vm.$t("COMMON.NONE")))])]):_vm._e(),(!!_vm.filteredSpot)?_c('div',{staticClass:"location-selected"},[(_vm.spots[_vm.currentSpot.spotId]?.id)?_c('div',{staticClass:"located"},[_c('div',{staticClass:"located-image"},[_c('img',{staticStyle:{"max-height":"35px","display":"inline"},attrs:{"src":_vm.spots[_vm.currentSpot.spotId].category.icon
                        ? _vm.spots[_vm.currentSpot.spotId].category.icon
                        : '/img/default-category-icon.svg',"alt":"icon"}})]),_c('div',{staticClass:"located-text"},[_c('span',[_vm._v(_vm._s(_vm.spots[_vm.currentSpot.spotId].code))]),_c('span',[_vm._v(" "+_vm._s(_vm.spots[_vm.currentSpot.spotId].spotType.name)+" ")])]),_c('div',{staticClass:"located-view"},[_c('button',{on:{"click":function($event){return _vm.viewSpot(_vm.spots[_vm.currentSpot.spotId])}}},[_c('img',{attrs:{"src":"/img/expand.svg","alt":"icon"}})])])]):_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.setFilteredSpotPosition(_vm.currentSpot)}}},[_vm._v(" "+_vm._s(_vm.$t("SPOTS.SET_SPOT_POSITION"))+" ")])]):_vm._e()])],1)]):_vm._e(),(_vm.currentSpot.x != 0 && _vm.currentSpot.y != 0)?_c('div',{staticClass:"hotspot-current",style:(_vm.getHotspotStyle(_vm.currentSpot))},[_c('span',{staticClass:"hotspot-marker"})]):_vm._e(),(
          _vm.spotDragShadow.x != 0 &&
          _vm.spotDragShadow.y != 0 &&
          _vm.currentSpot.isDragging
        )?_c('div',{staticClass:"hotspot-shadow",style:(_vm.getHotspotStyle(_vm.spotDragShadow))},[_c('span',{staticClass:"hotspot-marker"})]):_vm._e(),(_vm.showHelper && !_vm.showMapForm)?_c('div',{staticClass:"hotspot-helper",style:(_vm.getHotspotStyle(_vm.spotHelper)),on:{"click":_vm.addHotspot}},[_c('span',{staticClass:"hotspot-marker"})]):_vm._e(),_vm._l((_vm.hotspots.filter(
          (item) => item.x != 0 && item.y != 0
        )),function(hotspot,index){return _c('div',{key:index,staticClass:"hotspot",class:{
          searched:
            hotspot.spotId == _vm.selectedSpot ||
            hotspot.spotId == _vm.filteredSpot?.id,
        },style:(_vm.getHotspotStyle(hotspot)),attrs:{"title":hotspot.code},on:{"mousedown":function($event){$event.preventDefault();return ((event) => _vm.onMouseDownHotspot(event, hotspot)).apply(null, arguments)}}},[_c('span',{staticClass:"hotspot-marker"}),(_vm.showCodes)?_c('span',{staticClass:"hotspot-code"},[_vm._v(" "+_vm._s(hotspot.code)+" ")]):_vm._e()])}),_c('div',{ref:"zoomControlsContainer",staticClass:"zoom-controls"},[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.zoomIn.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-plus"})]),_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.zoomOut.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-minus"})])])],2),(!_vm.organizationConfig.SPOTS_MAP_IMAGE_URL)?_c('div',{staticClass:"no-image"},[_c('img',{attrs:{"src":"/img/map-placeholder.svg","alt":"map"}}),_c('p',[_vm._v(_vm._s(_vm.$t("COMMON.NO_IMAGE_ADDED")))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }